#main-welcome {
    padding: 20px;
}

#main-welcome h1 {
    text-align: center;
}

#main-welcome section {
    max-width: 800px;
    padding: 10px;
    margin: auto;
}

#main-welcome section img {
    width: 100%;
}

@media (min-width: 576px) {
    #main-welcome section img {
        float: right;
        max-width: 250px;
        margin-left: 20px;;
    }
}

#welcome-btn-container {
    display: flex;
    justify-content: center;
}

#welcome-btn-container button {
    box-shadow: 0 0 5px #aaa;
}