#form-modal-box .modal-title img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

#form-modal-box label {
    font-weight: bold;
}

.form-modal-attribute {
    margin-bottom: 0px;
}