.flip-card-back{
    background-color: #4998cb !important;
    transition: all 0.5s;
}

.flip-card .flash > h1 {
    background-color: #4998cb !important;
    color: #4998cb;
    transition: all 0.5s;
}

.living-card .flip-card-front {
    background-image: url("/images/background-blue-card.png");
    background-position: right 15px bottom 15px;
    background-size: 75%;
    background-repeat: no-repeat;
    background-color: #00709f;
}

.living-card .flip-card-front h1 {
    height: auto;
    width: auto;
}