.health-card .flip-card-back {
    background-color: #d0e072 !important;
    transition: all 0.5s;
}

.health-card.flip-card .flash > h1 {
    background-color: #dde89b !important;
    color: #000;
    transition: all 0.5s;
}

.health-card .flip-card-front {
    background-color: #d0e072;
    box-shadow: 0 0 5px #999;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.health-card .flip-card-back h1 {
    font-size: 22px;
}

.health-card .flip-card-front h1 {
    font-size: 40px;
    text-align: center;
    font-weight: normal;
    color: #fff;
    width: 75%;
    height: 75%;
    box-sizing: border-box;
    padding-top: 30%;
    margin-top: 0px;
    background-color: #dde89b;
}