.card-controller .basic-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: 20px;
    position: relative;
}

.card-controller .small-cards .basic-cards {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    margin: 20px;
    position: relative;
}

hr {
    clear: both;
}

.card-controller .attribute-cards, .card-controller .health-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    clear: both;

    margin: 20px;
}

#new-attribute-card {
    background-color: #ec93be;
    box-shadow: 0 0 5px #666;
    cursor: pointer;
    display: flex;
}

#new-attribute-card h1 {
    font-size: 40px;
    text-align: center;
    font-weight: normal;
    color: #fff;
    width: 77%;
    height: 82%;
    box-sizing: border-box;
    padding-top: 30%;
    background-color: #f0b0cf;
}

#new-health-card {
    background-color: #d0e072;
    box-shadow: 0 0 5px #666;
    cursor: pointer;
    display: flex;
}

#new-health-card h1 {
    font-size: calc(100%/10)px;
    text-align: center;
    font-weight: normal;
    color: #fff;
    width: 77%;
    height: 82%;
    box-sizing: border-box;
    padding-top: 30%;
    background-color: #dde89b;
}

.card-controller button {
    margin: auto;
    display: block;
}

.card-controller .alert {
    margin: 10px 10px -20px 10px;
    text-align: center;
    clear: both;
}

#choose-property-cards, #choose-health-cards, #choose-attribute-cards {
    position: relative;
}

.card-controller .rigth-btn{
    position: absolute;
    right: 10px;
    height: 40px;
    width: 40px;
    top: calc(50% - 20px);
}